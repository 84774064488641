* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  --primary: #2E03B7;
}

html,
body {
  height: 100%;
  overflow-x: hidden;
}

.page-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}