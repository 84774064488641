/* src/styles/ArticlePage.css */
.article-page {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 20px;
    color: #333;
    background-color: #f9f9f9;
    font-family: Arial, sans-serif;
    text-align: center;
}

.logo-article{
    position: absolute;
    width: 400px;
    right: 0;
    transform: rotate(90deg);
}

.article-title {
    font-size: 2rem;
    color: #1a73e8;
    margin-bottom: 10px;
}

.article-meta {
    font-size: 0.9rem;
    color: #666;
    margin-bottom: 20px;
}

.article-date {
    color: #666;
}

.article-author {
    color: #1a73e8;
    text-decoration: none;
}

.article-author:hover {
    text-decoration: underline;
}

.article-description {
    font-size: 1.1rem;
    color: #666;
    margin-bottom: 20px;
}

.article-content {
    max-width: 600px;
    font-size: 1rem;
    line-height: 1.6;
    color: #444;
    text-align: justify;
    margin-bottom: 20px;
}

.article-image {
    width: 100%;
    max-width: 600px;
    height: auto;
    border-radius: 8px;
    margin-top: 20px;
}

@media (max-width: 1200px){
.article-page{
    justify-content: center;
}

    .logo-article {
            position: relative;
            width: 40vw;
            transform: rotate(0deg);
        }
}