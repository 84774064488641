/* src/styles/CategoryPage.css */
.category-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 20px;
    color: #333;
    background-color: #f9f9f9;
    font-family: Arial, sans-serif;
}

.category-title {
    font-size: 1.8rem;
    color: var(--primary);
    margin-bottom: 20px;
}

.article-list {
    list-style: none;
    padding: 0;
    max-width: 600px;
    width: 100%;
}

.article-item {
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin: 10px 0;
    padding: 15px;
    transition: box-shadow 0.3s;
}

.article-item:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.article-link {
    text-decoration: none;
    color: #333;
}

.article-link h3 {
    color: #1a73e8;
    margin-bottom: 5px;
}

.article-link p {
    font-size: 0.9rem;
    color: #666;
}

/* src/styling/CategoryPage.css */

.back-home-button {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #2E03B7;
    color: #ffffff;
    text-decoration: none;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 600;
    transition: background-color 0.3s ease, transform 0.3s ease;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
}

.back-home-button:hover {
    background-color: #1f027d;
    transform: scale(1.05);
}

.back-home-button:active {
    background-color: #16015a;
    transform: scale(0.98);
}