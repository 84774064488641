/* src/styles/Home.css */

:root {
    --primary: #2E03B7;
    /* Main accent color */
    --background: #f5f5f5;
    /* Light background color */
    --text-dark: #333333;
    /* Dark text color */
    --text-light: #ffffff;
    /* Light text color */
    --font-family: 'Arial', sans-serif;
}

.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: var(--background);
    padding: 40px;
    font-family: var(--font-family);
    color: var(--text-dark);
}

.header {
    text-align: center;
    margin-bottom: 30px;
}

.logo {
    width: 25vw;
    height: auto;
    margin: 0px;
}

h1 {
    font-size: 2.5rem;
    color: var(--primary);
    margin: 10px 0;
    font-weight: bold;
    letter-spacing: 1px;
}

.header p {
    font-size: 1.2rem;
    color: var(--text-dark);
    margin: 0;
    line-height: 1.6;
}

.latest-articles-title {
    font-size: 1.8rem;
    color: var(--primary);
    margin: 0;
    font-weight: bold;
}

/* Slideshow styling */
.slideshow-container {
    width: 90%;
    max-width: 800px;
    margin-bottom: 30px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    overflow: hidden;
}

/* General button styling for categories */
.category-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    margin-top: 20px;
}

.category-button {
    background-color: var(--primary);
    color: var(--text-light);
    padding: 10px 30px;
    border-radius: 25px;
    font-size: 1rem;
    font-weight: 500;
    text-decoration: none;
    text-align: center;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    min-width: 160px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.category-button:hover {
    background-color: #145bb5;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
}

.category-button:active {
    background-color: #0f4a94;
    transform: scale(0.98);
}

/* Additional global typography and layout adjustments */
p {
    font-size: 1rem;
    color: var(--text-dark);
    line-height: 1.6;
    max-width: 800px;
    text-align: center;
    margin-top: 20px;
}

h2 {
    font-size: 1.8rem;
    color: var(--text-dark);
    margin-bottom: 15px;
}

@media (max-width: 768px) {
    .logo{
        width: 50vw;
    }
}