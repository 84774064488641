/* src/styles/CategoryList.css */
.category-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
}

.category-list h3 {
    font-size: 1.5rem;
    color: var(--primary);
    margin-bottom: 15px;
}

.category-buttons {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
}

.category-button {
    background-color: var(--primary);
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 25px;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 500;
    transition: all 0.3s ease;
}

.category-button:hover {
    background-color: #ffffff;
    color: var(--primary);
}

.category-button:active {
    background-color: #0f4a94;
    transform: scale(0.98);
}