/* src/styles/Slideshow.css */
.slideshow-container {
    position: relative;
    width: 90%;
    max-width: 800px;
    height: 50vh;
    overflow: hidden;
    border-radius: 12px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
}

.slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 1s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.slide.active {
    opacity: 1;
    z-index: 1;
    
}

.slide-image {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
    border-radius: 12px 12px 0 0;
}

.slide-info {
    background-color: rgb(255, 255, 255);
    color: #000000;
    width: 50%;
    bottom: 1rem;
    position: absolute;
    left: 0%;
    text-align: center;
    padding: 10px 20px;
    border-radius: 0 20px 20px 0;
}

.slide-info h3 {
    font-size: 1.2rem;
    margin: 5px 0;
}

.slide-info p {
    font-size: 0.9rem;
}

/* Responsive styles for mobile */
@media (max-width: 768px) {
    .slideshow-container {
        width: 100%;
        height: 60vh;
        border-radius: 0;
    }

    .slide-info {
        width: 90%;
        left: 5%;
        height: 50%;
        bottom: 1rem;
        padding: 15px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
    }

    .slide.active .slide-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 0;
        position: absolute;
        top: 0;
        left: 0;
    }

    .slide-info h3 {
        font-size: 1.5rem;
    }

    .slide-info p {
        font-size: 1.1rem;
    }
}